import React from "react";
import config from "../../config.json";
import { BurgerMenu } from "./BurgerMenu.js";
import { NavigationBar } from "./NavigationBar.js";
import { TabMenu } from "./TabMenu";

// https://stackoverflow.com/questions/46586165/react-conditionally-render-based-on-viewport-size
export class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileView: true
    };

    this.UpdateViewportData = this.UpdateViewportData.bind(this);
  }

  componentDidMount() {
    this.UpdateViewportData();
    window.addEventListener("resize", this.UpdateViewportData);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.UpdateViewportData);
  }

  UpdateViewportData() {
    this.setState({
      mobileView: window.innerWidth < 750
    });
  }

  render() {
    if (config.NavigationType === 2 || this.state.mobileView) {
      return <BurgerMenu pages={this.props.pages} />;
    } else if (config.NavigationType === 1) {
      return <NavigationBar pages={this.props.pages} />;
    } else if (config.NavigationType === 3) {
      return <TabMenu pages={this.props.pages} />;
    }
  }
}
