import React from "react";
import { CenterOverlay } from "./utils/CenterOverlay";
import PDFObject from "pdfobject";

export class Pdf extends React.Component {
  componentDidMount() {
    if (PDFObject.supportsPDFs) {
      PDFObject.embed(this.getFileAddress(), "#pdf");
    }
  }

  render() {
    return (
      <CenterOverlay>
        {PDFObject.supportsPDFs ? (
          <div id="pdf" />
        ) : (
          <div className="centredText">
            <a href={this.getFileAddress()}>Download PDF</a>
          </div>
        )}
      </CenterOverlay>
    );
  }

  getFileAddress() {
    return "/pdf/" + this.props.filename;
  }
}
