import React from "react";

export function EmbeddedMap(props) {
  return (
    <iframe
      title="embeddedMap"
      src={props.embedUrl}
      width="100%"
      height="400px"
      frameBorder="0"
      style={{ marginTop: 40 }}
      allowFullScreen
    />
  );
}
