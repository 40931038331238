import React from "react";
import { getPages } from "./pages.Service";
import { Navigation } from "./Navigation";
import { Routing } from "./Routing";
import config from "../../config.json";
import { InitialOverlay } from "../InitialOverlay";

export class Pages extends React.Component {
  state = {
    pages: null
  };

  componentDidMount() {
    document.title = config.PageTitle;

    this.loadPages();
  }

  loadPages() {
    getPages().then(res => {
      this.setState({
        pages: res.Pages
      });
    });
  }

  render() {
    return this.state.pages ? (
      <div id="outer-container">
        {config.InitialOverlayType ? <InitialOverlay /> : null}
        <Navigation pages={this.state.pages} />
        <Routing pages={this.state.pages} />
      </div>
    ) : null;
  }
}
