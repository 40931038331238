import React from "react";

export function CenterOverlay(props) {
  return (
    <div className="centerOverlayOuter">
      <div className="centerOverlayMiddle">
        <div
          className="centerOverlayInner transparentBackground"
          style={{
            padding: props.padding ? props.padding : 20,
            maxWidth: props.maxWidth
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
