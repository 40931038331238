import config from "../../config.json";
import { get } from "../utils/serviceUtils";

export async function getInfo(callback) {
  const apiPath = "/api/display/getinfo";
  const apiParams = {
    RestaurantId: config.RestaurantId,
    Culture: config.DefaultCulture
  };

  const filePath = `${apiPath}/Culture@${config.DefaultCulture}.json`;

  return get(filePath, apiPath, apiParams, callback);
}
