import React from "react";
import { NavLink } from "react-router-dom";
import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import config from "../../config.json";

export class TabMenu extends React.Component {
  state = { activeIndex: 0 };

  handleActiveIndexUpdate = activeIndex => this.setState({ activeIndex });

  render() {
    const links = this.props.pages.map(page => (
      <TabLink key={page.Path} page={page} />
    ));

    return (
      <div className="tab-menu">
        <TabBar
          activeIndex={this.state.activeIndex}
          handleActiveIndexUpdate={this.handleActiveIndexUpdate}
        >
          {links}
          <CallTab phoneNumber={config.Number} />
        </TabBar>
      </div>
    );
  }
}

function TabLink(props) {
  const page = props.page;

  return (
    <Tab
      label={page.Name}
      onClick={() => (window.location.href = page.Path)}
      active={cleanPath(window.location.pathname) === cleanPath(page.Path)}
    >
      <NavLink className="tabMenuLink" to={page.Path}>
        {page.Name}
      </NavLink>
    </Tab>
  );
}

function cleanPath(input) {
  return input
    .toLowerCase()
    .replace(/^\//, "")
    .replace(/\/$/, "");
}

function CallTab(props) {
  return (
    <Tab
      label="Call"
      onClick={() => (window.location.href = `tel:${props.phoneNumber}`)}
    >
      <FontAwesomeIcon icon={faPhone} size="2x" className="phone-icon" />
    </Tab>
  );
}
