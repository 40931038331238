import React from "react";
import { getMenu } from "./menu.Service";
import { CenterOverlay } from "../utils/CenterOverlay";

export class Menu extends React.Component {
  state = {
    menu: null
  };

  componentDidMount() {
    this.loadMenu(this.props.menuId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.menuId !== this.props.menuId) {
      this.loadMenu(nextProps.menuId);
    }
  }

  loadMenu(menuId) {
    getMenu(menuId).then(res => {
      this.setState({
        menu: res.Menu
      });
    });
  }

  render() {
    return this.state.menu ? (
      <CenterOverlay>
        <div className="header">{this.state.menu.Name}</div>
        <ItemList items={this.state.menu.Items} />
      </CenterOverlay>
    ) : null;
  }
}

function ItemList(props) {
  return props.items.map(item => <Item key={item.ItemId} item={item} />);
}

function Item(props) {
  const item = props.item;

  let rowClasses = null;
  let itemClasses = null;
  if (item.ItemType === 1) {
    rowClasses = "sectionTitle";
  } else if (item.ItemType === 2) {
    rowClasses = "menuItem";
  } else if (item.ItemType === 3) {
    rowClasses = "itemChoice";
    itemClasses = "italics";
  }

  if (item.Price) {
    rowClasses += " underline";
  }

  return (
    <div className={rowClasses}>
      <span className={itemClasses}>{item.Name}</span>
      <span className="right">{item.Price}</span>
    </div>
  );
}
