import axios from "axios";

export async function get(filePath, apiPath, params, callback) {
  return getFromFile(filePath)
    .catch(() => {
      return getFromApi(apiPath, params);
    })
    .then(callback);
}

async function getFromFile(filePath) {
  return fetch(filePath).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("Couldn't get static data");
    }
  });
}

async function getFromApi(apiPath, params) {
  return axios
    .get("http://localhost:2848" + apiPath, {
      params: params
    })
    .then(res => res.data);
}
