import config from "../../config.json";
import { get } from "../utils/serviceUtils";

export async function getStory(storyId, callback) {
  const apiPath = "/api/display/getstory";
  const apiParams = {
    RestaurantId: config.RestaurantId,
    Culture: config.DefaultCulture,
    StoryId: storyId
  };

  const filePath = `${apiPath}/StoryId@${storyId},Culture@${
    config.DefaultCulture
  }.json`;

  return get(filePath, apiPath, apiParams, callback);
}
