import React from "react";

export function ContactInfo(props) {
  let phoneNumberClasses = "phone-number centredText marginTop";

  return (
    <React.Fragment>
      <div className={phoneNumberClasses}>
        {props.contactInfo.PhoneNumber ? (
          <a href={`tel:${props.contactInfo.PhoneNumber}`}>
            {props.contactInfo.PhoneNumber}
          </a>
        ) : null}
      </div>
      <div className="centredText">
        {props.contactInfo.Email ? (
          <a href={`mailto:${props.contactInfo.Email}`}>
            {props.contactInfo.Email}
          </a>
        ) : null}
      </div>
      <div>
        {props.contactInfo.Address ? (
          <Address address={props.contactInfo.Address} />
        ) : null}
      </div>
    </React.Fragment>
  );
}

function Address(props) {
  return (
    <div className="centredText marginTop marginBottom">
      {props.address.map(addressLine => (
        <div key={addressLine}>{addressLine}</div>
      ))}
    </div>
  );
}
