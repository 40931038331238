import React from "react";
import ImageGallery from "react-image-gallery";
import { CenterOverlay } from "./utils/CenterOverlay";
import names from "../gallery.json";

export const Gallery = () => {
  const images = names.map(name => {
    const path = `/gallery/${name}`;
    return {
      original: path,
      thumbnail: path
    };
  });

  return (
    <CenterOverlay>
      <ImageGallery
        items={images}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </CenterOverlay>
  );
};
