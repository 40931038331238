import React from "react";
import { Route, Switch } from "react-router-dom";

import { Info } from "../Info/Info";
import { Gallery } from "../Gallery";
import { Menu } from "../Menus/Menu";
import { Pdf } from "../Pdf";
import { Instagram } from "../Instagram";
import { Error } from "../Error";
import { Story } from "../Story/Story";

if (typeof Object.assign != "function") {
  Object.assign = function(target) {
    "use strict";
    if (target == null) {
      throw new TypeError("Cannot convert undefined or null to object");
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

export function Routing(props) {
  var routes = props.pages.map(page => getPageRoute(page));

  return (
    <Switch>
      {routes}
      <Route component={Error} />
    </Switch>
  );
}

function getPageRoute(page) {
  const exact = page.Path === "/";
  let render = null;

  if (page.PageType === 1) {
    render = props => WrapWithMain(<Info {...props} />);
  } else if (page.PageType === 2) {
    render = props => WrapWithMain(<Menu {...props} menuId={page.Context} />);
  } else if (page.PageType === 3) {
    render = props => WrapWithMain(<Gallery {...props} />);
  } else if (page.PageType === 4) {
    render = props => WrapWithMain(<Pdf {...props} filename={page.Context} />);
  } else if (page.PageType === 5) {
    render = props => WrapWithMain(<Instagram {...props} />);
  } else if (page.PageType === 6) {
    render = props => WrapWithMain(<Story {...props} storyId={page.Context} />);
  }

  return (
    <Route key={page.Path} path={page.Path} exact={exact} render={render} />
  );
}

function WrapWithMain(item) {
  return <div id="page-wrap">{item}</div>;
}
