import React from "react";
import { slide as Menu } from "react-burger-menu";
import config from "../../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export class BurgerMenu extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    const links = this.props.pages.map(page => (
      <a id={page.Path} key={page.Path} className="menu-item" href={page.Path}>
        {page.Name}
      </a>
    ));

    return (
      <React.Fragment>
        <PhoneButton number={config.Number} />
        <TopBar title={config.Name} />
        <BurgerButton links={links} />
      </React.Fragment>
    );
  }
}

function PhoneButton(props) {
  return (
    <div>
      <a className="bm-phone-button" href={`tel:${props.number}`}>
        <FontAwesomeIcon icon={faPhone} size="2x" className="phone-icon" />
      </a>
    </div>
  );
}

function TopBar(props) {
  return (
    <div className="top-bar">
      <div className="top-bar-content">
        <div>
          <a className="header" id="top-bar-header" href="/">
            {props.title}
          </a>
        </div>
      </div>
    </div>
  );
}

function BurgerButton(props) {
  return (
    <Menu
      right={config.BurgerButtonOnRight}
      pageWrapId="page-wrap"
      outerContainerId="outer-container"
    >
      {props.links}
    </Menu>
  );
}
