import React from "react";
import { getInfo } from "./info.Service";
import { OpeningHours } from "./OpeningHours";
import { ContactInfo } from "./ContactInfo";
import { CenterOverlay } from "../utils/CenterOverlay";
import { EmbeddedMap } from "./EmbeddedMap";

export class Info extends React.Component {
  state = {
    info: null
  };

  componentDidMount() {
    getInfo().then(res => {
      this.setState({
        info: res
      });
    });
  }

  render() {
    return this.state.info ? (
      <CenterOverlay>
        <div className="header">{this.state.info.RestaurantName}</div>
        {this.state.info.ContactInfo ? (
          <ContactInfo contactInfo={this.state.info.ContactInfo} />
        ) : null}
        <OpeningHours openingHours={this.state.info.OpeningHours} />
        {this.state.info.ContactInfo &&
        this.state.info.ContactInfo.MapEmbedUrl ? (
          <EmbeddedMap embedUrl={this.state.info.ContactInfo.MapEmbedUrl} />
        ) : null}
      </CenterOverlay>
    ) : null;
  }
}
