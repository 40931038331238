import React from "react";
import moment from "moment";

export function OpeningHours(props) {
  const dayToday = new Date().getDay();
  const days = props.openingHours.DayOpeningHours.map(day => (
    <Day
      key={day.Day}
      day={day}
      today={dayToday}
      closed={props.openingHours.Closed}
    />
  ));

  return <div style={{ maxWidth: "400px", margin: "auto" }}>{days}</div>;
}

function Day(props) {
  const day = props.day;

  let classes = "underline";
  if (day.Day % 7 === props.today % 7) {
    classes += " today";
  }

  let hours = props.closed;
  if (day.OpenTimeRanges && day.OpenTimeRanges.length > 0) {
    hours = day.OpenTimeRanges.map(
      range =>
        `${getFormattedTime(range.OpeningTime)}-${getFormattedTime(
          range.ClosingTime
        )}`
    ).join(", ");
  }

  return (
    <div className={classes}>
      <span>{day.DayName}</span>
      <span className="right">{hours}</span>
    </div>
  );
}

// @@@ should receive a format from API
function getFormattedTime(timeString) {
  var time = moment(timeString, "HH:mm:ss");

  let minuteString = "";
  const minutes = time.minutes();
  if (minutes === 0) {
    minuteString = "";
  } else {
    minuteString = minutes;
  }

  return time.hours() + "h" + minuteString;
}
