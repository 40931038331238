import React from "react";
import { CenterOverlay } from "./utils/CenterOverlay";
import config from "../config.json";

export const Instagram = () => {
  return (
    <CenterOverlay padding={2} maxWidth={"800px"}>
      <iframe
        id="instagram-iframe"
        src={`//lightwidget.com/widgets/${config.InstagramEmbedId}.html`}
        scrolling="no"
        allowtransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: 0, overflow: "hidden" }}
        title="instagram-iframe"
      />
    </CenterOverlay>
  );
};
