import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Pages } from "./components/Pages/Pages";

import "./App.scss";
import "./font.css";
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    );
  }
}

export default App;
