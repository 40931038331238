import React from "react";

export class Video extends React.Component {
  componentDidMount() {
    document.getElementById("autoplay-video").play();
  }

  render() {
    return (
      <div className="video-container">
        <video
          playsInline
          autoplay
          muted
          loop
          controls="true"
          id="autoplay-video"
        >
          <source src="/videos/io.mp4" type="video/mp4" />
        </video>
      </div>
    );
  }
}
