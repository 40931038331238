import config from "../../config.json";
import { get } from "../utils/serviceUtils";

export async function getMenu(menuId, callback) {
  const apiPath = "/api/display/getmenu";
  const apiParams = {
    RestaurantId: config.RestaurantId,
    MenuId: menuId,
    Culture: config.DefaultCulture
  };

  const filePath = `${apiPath}/MenuId@${menuId},Culture@${
    config.DefaultCulture
  }.json`;

  return get(filePath, apiPath, apiParams, callback);
}
