import React from "react";
import { NavLink } from "react-router-dom";

export function NavigationBar(props) {
  const links = props.pages.map(page => (
    <NavigationLink key={page.Path} page={page} />
  ));

  return (
    <div className="transparentBackground" style={{ padding: 20 }}>
      {links}
    </div>
  );
}

function NavigationLink(props) {
  const page = props.page;

  return (
    <NavLink className="navLink" to={page.Path}>
      {page.Name}
    </NavLink>
  );
}
