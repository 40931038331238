import React from "react";
import config from "../config.json";
import { Video } from "./Video.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";

export class InitialOverlay extends React.Component {
  state = {
    overlayDisplayed: "true"
  };

  componentDidMount() {
    this.setState({
      overlayDisplayed: sessionStorage.getItem("overlay-displayed")
    });
  }

  render() {
    if (this.state.overlayDisplayed || document.location.pathname !== "/") {
      enableScroll();
      return null;
    } else {
      disableScroll();
      sessionStorage.setItem("overlay-displayed", "true");
    }

    const overlay = this.getOverlay();

    return <div onClick={() => this.closeOverlay()}>{overlay}</div>;
  }

  getOverlay() {
    if (config.InitialOverlayType === 1) {
      return (
        <div className="initial-overlay">
          <div className="centred centredText initial-overlay-text">
            <div className="header">{config.Name}</div>
            <div>{config.InitialOverlayText}</div>
          </div>
        </div>
      );
    }

    if (config.InitialOverlayType === 2) {
      return (
        <div>
          <Video />
          <div className="io-skip-container">
            <FontAwesomeIcon icon={faForward} size="2x" />
          </div>
        </div>
      );
    }
  }

  closeOverlay() {
    enableScroll();
    this.setState({
      overlayDisplayed: "true"
    });
  }
}

function disableScroll() {
  document.body.classList.add("disable-scroll");
}

function enableScroll() {
  document.body.classList.remove("disable-scroll");
}
