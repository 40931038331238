import React from "react";
import { CenterOverlay } from "./utils/CenterOverlay";
import config from "../config.json";

export function Error() {
  document.title = "404 - " + config.PageTitle;

  return (
    <CenterOverlay>
      <p className="centredText">404 error - page not found</p>
    </CenterOverlay>
  );
}
