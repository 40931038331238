import React from "react";
import { CenterOverlay } from "../utils/CenterOverlay";
import { getStory } from "./story.Service";
import Enumerable from "linq";

export class Story extends React.Component {
  state = {
    story: null
  };

  componentDidMount() {
    this.loadStory(this.props.storyId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.storyId !== this.props.storyId) {
      this.loadStory(nextProps.storyId);
    }
  }

  loadStory(storyId) {
    getStory(storyId).then(res => {
      this.setState({
        story: res.Story
      });
    });
  }

  render() {
    if (!this.state.story) {
      return null;
    }

    const images = this.state.story.Items.map((item, position) =>
      this.getItem(item, position)
    );

    return <CenterOverlay>{images}</CenterOverlay>;
  }

  getItem(item, position) {
    if (item.ItemType === 1) {
      return this.getImage(item.Key, position);
    } else if (item.ItemType === 2) {
      return this.getText(item.Key, position);
    } else if (item.ItemType === 3) {
      return this.getYoutubeVideo(item.Key, position);
    }
  }

  getImage(key, position) {
    const image = Enumerable.from(this.state.story.Images)
      .where(i => i.Key === key)
      .single();

    const classes = this.getClasses("story-image", position);

    return (
      <img
        className={classes}
        src={"/story/" + image.Filename}
        alt={key}
        key={position}
      />
    );
  }

  getText(key, position) {
    const text = Enumerable.from(this.state.story.Texts)
      .where(t => t.Key === key)
      .single();

    const paragraphs = text.Paragraphs.map((paragraph, i) => (
      <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ));

    const classes = this.getClasses("story-text", position);

    return (
      <div className={classes} key={position}>
        {paragraphs}
      </div>
    );
  }

  getYoutubeVideo(key, position) {
    const video = Enumerable.from(this.state.story.YoutubeVideos)
      .where(i => i.Key === key)
      .single();

      const classes = this.getClasses("story-yt", position);

    return(
      <div class={classes} key={position}>
        <iframe
          src={video.EmbedUrl}
          frameborder='0'
          allowfullscreen>
        </iframe>
      </div>
    );
  }

  getClasses(typeClass, position) {
    return (
      "story-item " + typeClass + (position === 0 ? " first-story-item" : "")
    );
  }
}
